<template>
  <div>
    <ReviewTable />
  </div>
</template>

<script>
import ReviewTable from "@/views/Reviews/Components/ReviewsTable.vue";

export default {
  components: {
    ReviewTable,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
